import React, { useState, useEffect } from 'react'

import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

import { SectionTitle } from '../components'

const Staff = () => {
    const builder = imageUrlBuilder(sanityClient)
    const [staff, setStaff] = useState(null)

    function urlFor(source) {
        return builder.image(source)
    }

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "staff"]|order(orderRank){
      name,
      bio,
      img,
      insta
    }`
            )
            .then((data) => setStaff(data))
            .catch(console.error);
    }, []);

    if (!staff) {
        return (
            <h1>Loading...</h1>
        )
    }

    return (
        <section id='staff' className='container px-6 flex flex-col items-center'>
            <SectionTitle title='Meet The Staff' />

            <div className="staff flex flex-wrap gap-[60px] justify-center items-center">
                {staff.map((person, index) => {
                    var even = (index % 2 === 0)

                    return (
                        <div className={`person w-[350px] flex-col ${even ? 'rotate-[-3deg]' : 'rotate-3'} hoverStaff hover:scale-[1.05] transition-all`} key={person + index}>
                            <img src={urlFor(person.img)} alt={person.name} className='w-full fancy-border h-[50%] object-cover' />

                            <div className="info p-4 my-4 w-full h-full top-0 left-0 flex flex-col items-center text-center text-white transition-all">
                                <p className='mb-4 text-xl font-bold'>{person.name}</p>
                                <p>{person.bio}</p>
                                <p className='mt-4 text-l font-bold'>{person.insta}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default Staff