import React, { useState, useEffect } from 'react'

import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

import { SectionTitle } from '../components'

const Awards = () => {
  const builder = imageUrlBuilder(sanityClient)
  const [awards, setAwards] = useState(null)

  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "awards"]|order(orderRank){
      name,
      img
    }`
      )
      .then((data) => setAwards(data))
      .catch(console.error);
  }, []);

  if (!awards) {
    return (
      <h1>Loading...</h1>
    )
  }

  return (
    <section id='awards' className='container px-6'>
      <SectionTitle title='Awards' />

      <div className="awards flex flex-wrap gap-[60px] justify-center items-center">
        {awards.map((award, index) => {
          var even = (index % 2 == 0)
          
          return (
            <div className={`award fancy-border w-[300px] h-[400px] ${even ? 'rotate-[-3deg]' : 'rotate-3'} hover:scale-[1.05] transition-all`} key={award + index}>
              <img src={urlFor(award.img)} alt={award.name} className='w-full h-full object-cover' />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default Awards