import React, { useState, useEffect } from 'react'

import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

import { SectionTitle, TextImage } from '../components'

const Fidgets = () => {
  const builder = imageUrlBuilder(sanityClient)
  const [figetsSection, setFigetsSection] = useState(null)

  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "fidgets"]|order(orderRank){
      text,
      img,
      btn
    }`
      )
      .then((data) => setFigetsSection(data))
      .catch(console.error);
  }, []);

  if (figetsSection) {
    var fidgets = figetsSection[0]
  } else {
    return (
      <h1>Loading...</h1>
    )
  }

  return (
    <section id='fidgets' className='container px-6'>
      {/* <SectionTitle title='Our Fidgets' /> */}
      <TextImage
        img={urlFor(fidgets.img)}
        imgAlt='fidgets'
        text={fidgets.text}
        reverse={true}
        btnText={fidgets.btn.text}
        btnLink={fidgets.btn.link}
      />
    </section>
  )
}

export default Fidgets