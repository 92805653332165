import React from 'react'

const TextImage = ({ img, imgAlt, text, reverse, btnText, btnLink }) => {

  return (
    <div className={`flex flex-col ${reverse ? 'sm:flex-row-reverse' : 'sm:flex-row'} sm:gap-[60px] gap-[60px] items-center`}>
      <div className="content flex-1 flex flex-col items-center sm:items-start">
        <p className='text-white text-lg mb-10'>{text}</p>

        <a href={btnLink} className='cursor-pointer text-white sm:m-0 mx-auto py-[10px] px-[16px] text-xl capitalize bg-[#22363f] button-hover'>{btnText}</a>
      </div>

      <div className="image flex-1">
        <img src={img} alt={imgAlt}
          className={`scale-[0.95] fancy-border ${reverse ? 'rotate-[-3deg]' : 'rotate-3'}`}
        />
      </div>
    </div>
  )
}

export default TextImage