import React from 'react'
import './App.css'

import { Footer } from './components'
import { Header, About, Fidgets, Awards, Gallery, FAQs, Contact, Staff } from './containers'
import { reviewImage } from './assets'

const App = () => {
  return (
    <main className='bg-[#080808] flex flex-col items-center overflow-hidden'>
      <Header />
      <About />
      <Fidgets />
      <Awards />
      <Gallery />
      <Staff />
      <FAQs />
      <a href="https://g.page/r/Ce_gR3wC5T5BEBM/review" className="review w-[250px]" target='_blank'>
        <img src={reviewImage} alt="leave a review" className='w-full h-full object-contain' />
      </a>
      <Contact />
      <Footer />
    </main>
  )
}

export default App