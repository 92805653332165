import React, { useState, useEffect } from 'react'
import { Navbar } from '../components'

import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

const Header = () => {
  const builder = imageUrlBuilder(sanityClient)
  const [headerSection, setHeaderSection] = useState(null)

  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "header"]|order(orderRank){
      title,
      para,
      bgImg,
      "imageUrl": bgImage.asset->url,
      btn
    }`
      )
      .then((data) => setHeaderSection(data))
      .catch(console.error);
  }, []);

  if (headerSection) {
    var header = headerSection[0]
  } else {
    return (
      <h1>Loading...</h1>
    )
  }

  return (
    <section className={`w-[100%] min-h-[100dvh] flex justify-center items-center flex-col bg-black`} id='home'>
      <img src={urlFor(header.bgImg)} alt="background"
        className='w-full h-full absolute object-cover z-0 opacity-25 pointer-events-none' />

      {/* ACUTAL CONTENT */}
      <Navbar />

      <div className="content max-w-[800px] text-center px-10 z-10 text-white">
        <h1 className='text-7xl mb-10 text-center glow-text'>{header.title}</h1>
        <p className='mb-10 text-lg'>{header.para}</p>
        <a href={header.btn.btnLink}
          className='py-[10px] px-[16px] text-xl capitalize bg-[#22363f] button-hover'
        >{header.btn.text}</a>
      </div>
    </section>
  )
}

export default Header