import React from 'react'

import { AiOutlineInstagram, AiFillFacebook } from 'react-icons/ai'

const Footer = () => {
  return (
    <footer className='p-6 text-white text-md'>
        <div className="links flex items-center justify-center gap-3 mb-2">
            <a href="https://www.instagram.com/houseofhandsomebedford/" className='w-[30px] h-[30px]'><AiOutlineInstagram className='w-full h-full' /></a>
            <a href="https://www.facebook.com/houseofhandsomebedford" className='w-[30px] h-[30px]'><AiFillFacebook className='w-full h-full' /></a>
        </div>

        Made by <a className='fancy-hover' href="http://thomasprice.me/Portfolio/" target='_blank'>Thomas Price</a>
    </footer>
  )
}

export default Footer