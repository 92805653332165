import React, { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'

const FAQ = ({ question, answer}) => {
  const [accOpen, setAccOpen] = useState(false)

  return (
    <div className='mb-4'>
        <div className="question flex justify-between items-center p-3 cursor-pointer text-white bg-[#22363f]" onClick={() => setAccOpen((prev) => !prev)}>
          <p className='text-md'>{question}</p>
          <AiOutlinePlus className={`w-[20px] h-[20px] transition-all ${accOpen ? 'rotate-45' : ''}`} />
        </div>
        <div className={`answer transition-all ${accOpen ? 'flex' : 'hidden'} bg-[#22363f] p-3`}>
          <p className='text-white'>{answer}</p>
        </div>
    </div>
  )
}

export default FAQ