import React, { useState, useEffect } from 'react'

import sanityClient from '../client'
import imageUrlBuilder from '@sanity/image-url'

import { SectionTitle, FAQ } from '../components'

const FAQs = () => {
  const builder = imageUrlBuilder(sanityClient)
  const [FAQs, setFAQs] = useState(null)

  function urlFor(source) {
    return builder.image(source)
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "faqs"]|order(orderRank){
      question,
      answer
    }`
      )
      .then((data) => setFAQs(data))
      .catch(console.error);
  }, []);

  if (!FAQs) {
    return (
      <h1>Loading...</h1>
    )
  }

  return (
    <section id='faqs' className='container px-6'>
        <SectionTitle title='FAQs' />

        <div className="faqs max-w-[800px] mx-auto p-4">
          {FAQs.map((faq, index) => (
            <FAQ
              key={faq + index}
              question={faq.question}
              answer={faq.answer}
            />
          ))}
        </div>
    </section>
  )
}

export default FAQs